import React from "react";   // Import app.css
function App() {
  return (
    <>
     
      <div className="h-screen flex flex-col items-center justify-center bg-black">
        <h1 className="text-6xl font-neuroploitical text-white">Looka</h1><br /><br />
        <div className="text-center p-6 bg-white bg-opacity-5 backdrop-blur-lg rounded-lg shadow-md">
          <h1 className="text-4xl font-bold text-white mb-4">Coming Soon!</h1>
          <p className="text-gray-300 text-lg mb-6">
            Our website is under development. We are working hard to launch it soon. Stay tuned!
          </p>
          <Countdown targetDate="2025-02-01T00:00:00" />
          <p className="text-gray-400 mt-6">Thank you for your patience!</p>
        </div>
      </div>
    </>
  );
}

// Countdown Component
const Countdown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = React.useState({});

  React.useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const target = new Date(targetDate).getTime();
      const difference = target - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
      } else {
        setTimeLeft(null);
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft();
    return () => clearInterval(timer);
  }, [targetDate]);

  if (!timeLeft) {
    return <p className="text-lg text-gray-300">We are live! 🎉</p>;
  }

  return (
    <div className="flex justify-center space-x-4 text-gray-100 text-xl font-bold">
      <div>
        <p>{timeLeft.days}</p>
        <span>Days</span>
      </div>
      <div>
        <p>{timeLeft.hours}</p>
        <span>Hours</span>
      </div>
      <div>
        <p>{timeLeft.minutes}</p>
        <span>Minutes</span>
      </div>
      <div>
        <p>{timeLeft.seconds}</p>
        <span>Seconds</span>
      </div>
    </div>
  );
};

export default App;
